import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import { Col, Container, Row } from "react-grid-system"
import HeroHeader from "../components/heroHeader"
import TextBlock from "../components/textBlock"
import {
  getArticlePath,
  getBlogPath,
  getNewsArticlePath,
  getNewsPath,
} from "../components/pathUtils"
import { ChevronLeft } from "react-feather"
import { LanguageContext } from "../locales/langContext"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Flex } from "@theme-ui/components"

const Article = ({ data: { article }, pageContext, location }) => {
  const lang = pageContext.locale

  const i18nPaths = article._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value:
        pageContext.category === "blog"
          ? getArticlePath(article, path.locale)
          : getNewsArticlePath(article, path.locale),
    }
  })

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    headline: article.seo ? article.seo.title || article.title : article.title,
    author: {
      "@type": "Organization",
      name: "Gavazzi S.p.a.",
      logo: {
        "@type": "ImageObject",
        url:
          "https://www.gavazzispa.it/static/gavazzi-logo-cee9f73306f0b4c4e1f5901cdccae880.png",
      },
    },
    image: {
      "@type": "ImageObject",
      url: article.heroImage.fluid.src,
    },
    datePublished: article.meta.firstPublishedAtIso,
    publisher: {
      "@type": "Organization",
      name: "Gavazzi S.p.a.",
      logo: {
        "@type": "ImageObject",
        url:
          "https://www.gavazzispa.it/static/gavazzi-logo-cee9f73306f0b4c4e1f5901cdccae880.png",
      },
    },
    dateModified: article.meta.updatedAt,
    description: article.seo ? article.seo.description : null,
    url: `https://www.gavazzispa.it${
      pageContext.category === "blog"
        ? getArticlePath(article, lang)
        : getNewsArticlePath(article, lang)
    }`,
    identifier: `https://www.gavazzispa.it${
      pageContext.category === "blog"
        ? getArticlePath(article, lang)
        : getNewsArticlePath(article, lang)
    }`,
  }

  return (
    <Layout
      lang={lang}
      location={location}
      i18nPaths={i18nPaths}
      allSlugLocales={article._allSlugLocales}
    >
      <HelmetDatoCms seo={article.seoMetaTags}>
        <html lang={lang} />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetDatoCms>
      <HeroHeader
        title={article.title}
        subtitle={article.subtitle}
        heroImage={article.heroImage}
        highlight={article.highlight}
      />
      <Wrapper>
        <Container>
          <Row justify="center">
            <Col lg={8}>
              <TextBlock text={article.text} />
              <LanguageContext.Consumer>
                {t => (
                  <Link
                    to={
                      pageContext.category === "blog"
                        ? getBlogPath(lang)
                        : getNewsPath(lang)
                    }
                  >
                    <Flex sx={{ flexDirection: "row" }}>
                      <ChevronLeft /> <p>{t.back}</p>
                    </Flex>
                  </Link>
                )}
              </LanguageContext.Consumer>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`

export default Article

export const query = graphql`
  query ArticleQuery($slug: String!, $locale: String!) {
    article: datoCmsArticle(slug: { eq: $slug }, locale: { eq: $locale }) {
      ...ArticleAllSlugLocales
      heroImage {
        fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      slug
      text
      locale
      id
      meta {
        firstPublishedAtIso: firstPublishedAt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seo {
        title
        description
      }
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }
`
