import React from "react"
import styled from "styled-components"
import { Box } from "theme-ui"
import { Heading } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"
import customBorder from "../images/custom-border.svg"

const TextBlock = ({ title, text, highlight, fullwidth }) => (
  <>
    {fullwidth ? (
      <Wrapper>
        <Text title={title} text={text} highlight={highlight} />
      </Wrapper>
    ) : (
      <Text title={title} text={text} highlight={highlight} />
    )}
  </>
)

const Text = ({ title, text, highlight }) => (
  <Box
    sx={{ py: 5, background: highlight ? "primary" : "light" }}
    highlight={highlight}
  >
    <StyledBox>
      {title && <Heading>{title}</Heading>}
      <RichContentTextBlock html={text} />
    </StyledBox>
  </Box>
)

const Wrapper = styled.div``

const StyledBox = styled.div`
  padding-top: 2rem;
  background-image: url(${customBorder});
  background-repeat: no-repeat;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default TextBlock
